import React from "react"

import { Container, Row, Col } from "react-bootstrap" 
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import { MDXRenderer } from "gatsby-plugin-mdx"
import MDXLayout from "../../components/mdxlayout"

import { GatsbyImage } from "gatsby-plugin-image"

import Documents from "../../components/documents"
import publicationDateRendererHOC from "../../components/publication-date-rendererHOC"
import moment from "moment"
import "moment/locale/fr"


const Evenement = ({ displayData, location, data, publicationDate }) => {
	const {
		imageHeader,
		titre,
		document,
		description,
		date
	} = displayData;
	const imageHeaderShow = imageHeader ? <GatsbyImage alt="événements" className="rounded img-center" image={imageHeader.localFile.childImageSharp.gatsbyImageData} /> : null

	return (
		<Layout location={location}>
			<SEO title={titre} />
			<Container>
				<Row className="text-center" style={{ marginTop: '20px' }}>
					<Link to="/actualites/agenda" className="btn btn-primary btn-white btn-sm">Vers tous les évenements</Link>
				</Row>
				<Row className="justify-content-center text-center mb-5">
					<Col xs={12}>
						<h2>{titre}</h2>
						<p className="publication-date">{'publié le ' + publicationDate}</p>
					</Col>
					<Col xs={12}>
						<p>{`Date de l'évenement: `  + moment(new Date(date)).format('LLLL')}</p>
					</Col>
					<Col xs={12} lg={6}>
						{imageHeaderShow}
					</Col>
				</Row>
				<Row className="text-justify mb-5">
					<Col>
						<MDXLayout><MDXRenderer>{description.markdownNode.childMdx.body}</MDXRenderer></MDXLayout>
					</Col>
				</Row>
				<Row>
					<Documents documents={document} />
				</Row>
			</Container>
		</Layout>
	)
}

export default publicationDateRendererHOC(Evenement, 'data.graphCmsEvenement')

export const EvenementQuery = graphql`
query EvenementQuery($id: String) {
	graphCmsEvenement(id: { eq: $id}) {
		imageHeader {
			localFile {
				childImageSharp {
					gatsbyImageData(layout: CONSTRAINED, height: 400, quality: 100),
				}
			}
		}
		description {
			markdownNode {
				childMdx {
					body
				}
			}
		}
		document {
			fileName
			url
		}
		...EvenementData
	}
}
`
